import { useRoute } from '@react-navigation/native';
import { NativeStackHeaderProps } from '@react-navigation/native-stack';
import { FullScreenModalHeader } from '@wearemojo/ui-components';
import { useCallback } from 'react';

import useTrackModalClose from '../hooks/analytics/useTrackModalClose';
import { useAppSelector } from '../hooks/useAppSelector';
import { getToParams } from '../navigation/links';
import ScreenKey from '../navigation/ScreenKey';
import { useSupportSheetContext } from '../providers/SupportSheetProvider';
import { selectIsLoggedIn } from '../store/session';
import IntercomButton from './IntercomButton';
import ModalScreenHeader from './ModalScreenHeader';

export type ModalNavigatorHeaderProps = NativeStackHeaderProps & {
	variant?: 'modal' | 'fullScreen';
	defaultParentScreen: ScreenKey;
	overrideOnClose?: () => void;
	options: {
		onPressBack?: () => void;
		onPressActionEffect?: () => void;
		hideCloseButton?: boolean;
		showBackIcon?: boolean;
		disableTrackModalClosed?: boolean;
		showSupportButton?: boolean;
		showHelpButton?: boolean;
		transparent?: boolean;
	} & NativeStackHeaderProps['options'];
};

const ModalNavigatorHeader = ({
	variant = 'modal',
	defaultParentScreen,
	overrideOnClose,
	navigation,
	options: {
		title,
		onPressBack,
		onPressActionEffect,
		hideCloseButton,
		showBackIcon,
		disableTrackModalClosed,
		showSupportButton,
		showHelpButton,
		transparent,
	},
}: ModalNavigatorHeaderProps) => {
	const trackModalClose = useTrackModalClose();
	const { onPressSupport } = useSupportSheetContext();

	const route = useRoute();

	const onPress = useCallback(() => {
		onPressActionEffect?.();

		if (onPressBack) {
			onPressBack();
			return;
		}

		if (!disableTrackModalClosed) {
			trackModalClose();
		}
		if (overrideOnClose) {
			overrideOnClose();
			return;
		}
		const parent = navigation.getParent();
		const { screen: name, params } = getToParams(defaultParentScreen);
		parent?.canGoBack() ? parent?.goBack() : navigation.navigate(name, params);
	}, [
		defaultParentScreen,
		navigation,
		onPressActionEffect,
		onPressBack,
		trackModalClose,
		overrideOnClose,
		disableTrackModalClosed,
	]);

	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	if (!isLoggedIn) {
		return null;
	}

	if (variant === 'modal') {
		return (
			<ModalScreenHeader
				title={title}
				showBackIcon={!!onPressBack}
				onPressClose={!hideCloseButton ? onPress : undefined}
				helpContext={`modal/${route.name}`}
			/>
		);
	}

	return (
		<FullScreenModalHeader
			showBackIcon={showBackIcon}
			onPressClose={!hideCloseButton ? onPress : undefined}
			onPressSettings={showSupportButton ? onPressSupport : undefined}
			HelpButton={
				showHelpButton ? (
					<IntercomButton type={'support'} context={`modal/${route.name}`} />
				) : undefined
			}
			reverseButtons={showHelpButton}
			transparent={transparent}
		/>
	);
};

export default ModalNavigatorHeader;
